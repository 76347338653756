<template lang="pug">
  b-card.manager-accounts-organizations-list.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-card-header.bg-transparent.border-0
      wc-tables-header(
        :title="$t('activerecord.models.organization.other')"
        :action-new='policies.new'
        route-new=''
        @click-data-clean-fetch='dataCleanFetch'
        @click-data-fetch='dataFetch')

    b-card-body.pt-0
      b-row
        b-col
          wc-tables-filter(
            :filter.sync='filter'
            :p-page.sync='pagination.pPage'
            :p-page-options='pagination.pPageOptions'
            @submit='dataFetch'
            @submit-clear='filterClear')

      b-row
        b-col
          transition(name='fade')
            wc-tables-wrapper-default(
              :ref='wns'
              :items='items'
              :fields='fields'
              :sort-by.sync='sorting.sortBy'
              :sort-desc.sync='sorting.sortDesc'
              :is-loading='isLoading'
              :action-delete='actionDestroy'
              @row-clicked='rowClicked'
              @row-delete='rowDelete'
              @sort-changed='sortingChanged'
              attr-active)
              template(v-slot:cell(attributes.roles_account)='data')
                template(v-for='(role, index) in data.item.attributes.roles_account')
                  b-badge.p-2.shadow-sm(
                    variant='light'
                    :title="$t(`role.codes.${role}`)"
                    v-b-tooltip.hover
                    pill)
                    i.fal.fa-fw.fa-user
                    |  {{ $t(`role.codes.${role}`) }}

    b-card-footer.border-0.bg-white
      wc-tables-footer(
        :total-items='pagination.totalItems'
        :p-page='pagination.pPage'
        :c-page.sync='pagination.cPage')

    loading(
      :active.sync='isLoading'
      :can-cancel-esc='true'
      :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/accounts/shared/tabs'
import apiOrganizations from '@services/api/manager/accounts/organizations'
import Table from '@common/table'

export default {
  name: 'manager-accounts-organizations-list',
  mixins: [Tabs, Table],
  props: {
    wns: {
      type: String,
      default: 'org',
    },
  },
  methods: {
    routeParams(item) {
      return {
        edit: [
          {
            name: 'manager-organizations-edit',
            params: { organization_id: item.attributes.sid },
          },
        ],
      }
    },
    apiParams() {
      return {
        list: [{ account_id: this.account_id }, this.sortingParams],
      }
    },
  },
  data() {
    return {
      apiBase: apiOrganizations,
      actionRowClicked: 'edit',
      account_id: this.$route.params.account_id,
      fields: [
        {
          key: 'id',
          label: this.$t('activerecord.attributes.shared.id'),
          sortable: true,
          attr: 'organizations.id',
          class: 'dots-100 h-100',
          noClick: true,
        },
        {
          key: 'attributes.name',
          label: this.$t('activerecord.models.organization.one'),
          sortable: true,
          attr: 'organizations.name',
        },
        {
          key: 'attributes.roles_account',
          label: this.$t('activerecord.models.role.other'),
          sortable: false,
          attr: 'organizations.roles_account',
        },
        {
          key: 'attributes.discarded_at',
          label: this.$t('activerecord.attributes.shared.discarded_at'),
          sortable: true,
          attr: 'organizations.discarded_at',
        },
        {
          key: 'attributes.updated_at',
          label: this.$t('activerecord.attributes.shared.updated_at'),
          sortable: true,
          attr: 'organizations.updated_at',
        },
        {
          key: 'attributes.created_at',
          label: this.$t('activerecord.attributes.shared.created_at'),
          sortable: true,
          attr: 'organizations.created_at',
        },
        {
          key: 'delete',
          label: this.$t('shared.actions.delete'),
          class: 'text-right',
        },
      ],
    }
  },
}
</script>
